@import url(./variables.scss);
/** Common Css **/
@media only screen and (max-width: 600px) {
  #launcher {
    // Zendesk style
    bottom: 45px !important;
    margin: 10px 4px !important;
  }
}
.btn-action {
  margin: 0 5px 0 10px !important;
  padding: 5px 12px !important;
  border-radius: 30px !important;
}
.profileInfo {
  div {
    flex-wrap: wrap;
  }
}
.mainTableContainer {
  padding: 0 12px;
}
.break-all-cmn {
  word-break: break-all;
}
.notifications {
  text-align: left;
  padding-bottom: 60px;
  .lable {
    position: relative;
    margin-top: 40px;
    &:before {
      content: "";
      background-color: #bababa;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 11px;
    }
    em {
      background-color: #ffffff;
      padding: 0px 16px 0px 0;
      display: inline-block;
      position: relative;
      color: #555555;
      font-style: normal;
    }
  }
  .list {
    position: relative;
    text-align: left;
    margin: 40px 0 0 50px;
    small {
      width: 13px;
      height: 13px;
      background-color: var(--color-primary);
      border-radius: 100%;
      position: absolute;
      left: -27px;
      top: 5px;
    }
    strong {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-weight: 300;
      margin-top: 10px;
      font-size: 0.9rem;
    }
  }
}
.social-main {
  padding-left: 0 !important;
  padding-right: 0 !important;
  align-items: center !important;
  .social-icons {
    &__btn {
      margin: 0 5px 0 10px;
      padding: 5px 12px;
      border-radius: 30px;
    }
    &__icon {
      margin: 5px 2px 1px 2px;
      vertical-align: middle;
    }
    &__insta {
      width: 16px;
      vertical-align: middle;
      margin-left: 2px;
      cursor: pointer;
    }
  }
  .earnings-icons {
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: center !important;
    .earnings-icons {
      &__btn {
        margin: 0 5px 0 10px;
        padding: 5px 12px;
        border-radius: 30px;
      }
      &__icon {
        margin: 5px 2px 1px 2px;
        vertical-align: middle;
      }
    }
  }
}

.total-earnings {
  @media only screen and (max-width: 600px) {
    padding-right: 12px;
  }
  span {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    a {
      color: #592ee5;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__total {
    background: linear-gradient(167.98deg, #8a67fe -19.44%, #592ee5 108.74%);
    padding: 15px 24px;
    color: #ffffff;
    border-radius: 8px;
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    strong {
      font-weight: 500;
      small {
        font-weight: 300;
        font-size: 17px;
      }
    }
  }
}

.landing-page {
  // background: url(../src/assets/images/light_wave_background.png) left -260px no-repeat;
  height: 100vh;
  position: relative;
  bottom: -15px;
  background-size: cover;
  background-position: bottom;

  @media screen and (max-width: 768px) {
    //do Smth
    bottom: 0px;
    padding-top: 0px !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 0px !important;
    bottom: -15px;
  }
  &__link {
    font-size: 16px;
    font-weight: 300;
    a {
      text-decoration: none;
      font-weight: 500;
    }
  }
  &__termslink {
    font-size: 11px;
    font-weight: 300;
    padding: 0 30px;
    a {
      text-decoration: none;
      font-weight: 500;
    }
  }

  &__seprator {
    margin-left: 5px;
    margin-right: 5px;
  }
  &__info {
    padding-top: 31px;
    margin-bottom: 20px;
    .container {
      padding: 0 30px !important;
    }
    strong {
      font-size: 30px;
      font-weight: 600;
      color: #592ee5;
    }
    .landingpageimg {
      max-width: 100%;
      height: auto;
      padding: 0;
      margin: 0;
    }
    .direct {
      margin-bottom: 60px;
    }
    p {
      font-size: 15px;
      font-weight: 300;
      color: #202020;
    }
    a {
      margin-top: 20px;
    }
    .learn {
      margin-top: 40px;
      color: #202020;
      font-size: 16px;
    }
    .socialContainer {
      margin-top: 10px;
    }
    .socialContainer div span img {
      cursor: pointer;
    }

    .mnplink {
      margin-top: 15px;
    }
    .mnplink a {
      font-size: 16px;
    }
  }
}

.earnings {
  &__date {
    min-width: 110px;
    max-width: 110px;
    font-size: 12px !important;
    padding-left: 4px;
    @media only screen and (max-width: 600px) {
      min-width: 110px;
    }
  }
  &__name {
    min-width: 19em;
    max-width: 30em;
    font-size: 12px !important;
    padding-left: 4px;
    padding-right: 5px;
    word-break: break-all;
    @media only screen and (max-width: 600px) {
      min-width: 110px;
    }
  }
  &__amount {
    font-size: 14px !important;
    min-width: 140px;
    max-width: 140px;
    text-align: left;
    padding-left: 0 !important;
    @media only screen and (max-width: 600px) {
      min-width: 110px;
    }
  }
}
.thank_you_landing {
  background: url(../src/assets/images/thank_you_waves.png) 0 bottom no-repeat
    #592ee5;
  background-size: contain;
  .thank_you {
    color: #ffffff;
    margin-top: 0 !important;
    strong {
      color: #ffffff;
      margin-top: 60px;
      display: inline-block;
    }
    small {
      display: block;
      margin-top: 16px;
      font-size: 13px;
      font-weight: 500;
    }
    span {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin: 0 auto;
    }
    img {
      margin: 40px 0;
    }
    a {
      padding: 15px 50px;
      margin-top: 60px;
      color: #592ee5;
    }
  }
}

.total-about {
  span {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    a {
      color: #24054d;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__total {
    background: linear-gradient(167.98deg, #24054d -19.44%, #620bd2 108.74%);
    padding: 15px 24px;
    color: #ffffff;
    border-radius: 8px;
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    strong {
      font-weight: 500;
      small {
        font-weight: 300;
        font-size: 17px;
      }
    }
  }
}

.main-home {
  &__body {
    background-color: #f3f3f3;
    padding-left: 24px;
    border-radius: 26px 0px 0px 26px;
    @media only screen and (max-width: 600px) {
      padding: 0 12px !important;
    }
  }
  &__search-layout {
    position: relative;
    @media only screen and (max-width: 600px) {
      padding: 1px 12px !important;
    }
    img {
      position: absolute;
      margin: 13px 0 0 -44px;
      height: 25px;
    }
  }
  &__search {
    background: rgba(175, 11, 253, 1);
    background: linear-gradient(rgba(175, 11, 253, 1), rgba(98, 11, 210, 1));
    padding: 60px 0;
    h1 {
      font-size: 26px;
      color: #ffffff;
      font-weight: 500;
      margin-top: 0;
    }
  }
  &__input {
    background-color: #ffffff;
    min-height: 50px;
    min-width: 500px;
    border-radius: 7px;
    padding-right: 70px;
    @media only screen and (max-width: 600px) {
      min-width: auto;
    }
    svg {
      width: 60px;
      height: 30px;
      opacity: 0.3;
    }
    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      text-indent: 50px;
    }
  }
  &__tabs {
    margin-top: 10px;
    .dialog-box {
      cursor: pointer;
    }
    img {
      width: 100%;
      object-fit: cover;
      min-height: 190px;
      max-height: 180px;
      border-radius: 8px;
      // max-width: 248px;
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        min-height: 145px;
      }
    }
    .mainContainer {
      margin-top: 0;
    }
    .css-19kzrtu {
      padding: 24px 0;
    }
    &.fan_page {
      .carousalmainContainer {
        padding: 0 8px;
        img {
        }
        div {
          left: 30px;
          max-width: 210px;
          text-align: left;
          @media only screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  &__header {
    background-color: #ffffff;
    min-height: 80px;
    display: flex;
    padding: 0 22px;
    h4 {
      font-size: 16px;
      color: rgba(64, 8, 136, 1);
      font-weight: 500;
    }
    .user {
      display: flex;
      span {
        display: inline-block;
        margin: 0 13px 0 20px;
        text-align: right;
        strong {
          display: block;
          font-size: 14px;
          color: rgba(0, 0, 0, 1);
          font-weight: 500;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          color: rgb(181 181 181);
        }
      }
      a {
        position: relative;
        em {
          font-style: normal;
          width: 18px;
          height: 18px;
          background-color: #8743df;
          border-radius: 100%;
          display: inline-block;
          color: #ffffff;
          font-size: 10px;
          line-height: 18px;
          position: absolute;
          top: -6px;
          left: 12px;
        }
      }
      svg {
        &:last-child {
          height: 46px;
          width: 46px;
          color: #cccccc;
        }
      }
    }
  }
  &__athlete {
    background: rgba(175, 11, 253, 1);
    background: linear-gradient(rgba(175, 11, 253, 1), rgba(98, 11, 210, 1));
    padding: 30px 0 30px 30px;
    color: #ffffff;
    .profile {
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      @media only screen and (max-width: 600px) {
        padding: 0 8px;
      }
      &__info {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        .imgProfile {
          width: 110px;
          height: 110px;
          border-radius: 100%;
          border: 5px solid #ffffff;
        }
        span {
          display: inline-block;
          margin-left: 26px;
          strong {
            display: block;
            font-size: 26px;
            font-weight: 500;
          }
          small {
            display: block;
            em {
              font-style: normal;
              font-size: 12px;
              font-weight: 300;
              font-weight: 300;
              letter-spacing: 1px;
              margin-right: 30px;
              display: inline-block;
              margin-bottom: 8px;
              @media only screen and (max-width: 600px) {
                width: 100%;
              }
              svg {
                vertical-align: sub;
                font-size: 17px;
              }
            }
          }
          svg {
            font-size: 17px;
          }
          img {
            width: 15px;
          }
        }
        .social {
          margin-top: 16px;
          @media only screen and (max-width: 600px) {
            margin-top: 0px;
          }
          svg {
            margin-right: 20px;
          }
        }
      }
      h5 {
        color: rgba(192, 150, 227, 1);
        font-weight: 500;
        font-size: 18px;
        margin: 8px 0;
      }
      .readMoreButton {
        color: rgba(192, 150, 227, 1);
      }
      .athleteHomeSnapChatIcon {
        width: 20px;
      }
    }
    .total-earnings {
      &__fans {
        background-color: #3b2258;
        border-radius: 10px 0 0 10px;
        @media only screen and (max-width: 600px) {
          border-radius: 10px;
        }
        span {
          display: inline-block;
          margin: 30px 10px;
          small {
            display: block;
            font-size: 14px;
            font-weight: 400;
          }
          strong {
            font-size: 26px;
            font-weight: 600;
          }
        }
      }
      &__earnings {
        background: rgba(175, 11, 253, 1);
        background: linear-gradient(
          rgba(175, 11, 253, 1),
          rgba(98, 11, 210, 1)
        );
        border-radius: 10px 0 0 10px;
        margin-top: 40px;
        padding: 30px 30px 30px 60px;
        text-align: left;
        @media only screen and (max-width: 600px) {
          border-radius: 10px;
        }
        span {
          font-size: 22px;
          font-weight: 300;
        }
        strong {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }
  }
}

.left-container {
  .MuiButton-textPrimary {
    min-height: 50px;
  }
  .menuItemActive {
    border-left: 0;
    background-color: #f3f3f3;
    border-radius: 8px 0px 0px 8px;
    span {
      color: rgba(41, 41, 41, 1);
    }
    .nv-icon {
      background-color: #ffffff;
      box-shadow: 0px 16px 31px rgb(145 96 209 / 28%);
      border-radius: 8px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 50px;
      margin-left: 0;
    }
    .img-hover {
      background: #ffffff;
      box-shadow: 0px 16px 31px rgba(145, 96, 209, 0.28);
      border-radius: 18px;
    }
  }
  .css-1o3jpn7 {
    margin-right: 0;
  }
  .MuiBox-root {
    margin-right: 0;
  }
  .MuiDrawer-paper {
    border-right: 0;
  }
  .MuiListItemText-primary {
    font-size: 14px;
    color: rgb(157 157 157);
    font-weight: 500;
    padding-left: 20px;
  }
  li {
    button {
      .nv-icon {
        display: inline-block;
        width: 40px;
        text-align: center;
        margin-left: 10px;
        img {
          height: 18px;
          margin-top: 7px;
        }
      }
    }
  }
  .css-14nfmyp-MuiStack-root {
    .nv-icon {
      display: none;
    }
  }
}
.carousal-dialog {
  .css-xw1cft-MuiPaper-root-MuiDialog-paper {
    background-color: #ffffff;
  }
  .css-1x1xx5k {
    max-width: 100%;
    overflow-x: hidden;
  }
  .css-ylyhao-MuiPaper-root-MuiMobileStepper-root {
    margin-top: 40px;
  }
  .MuiPaper-elevation0 {
    height: inherit;
  }

  // img {width: 100%;  height: 500px;
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat; }

  .MuiTypography-h6 {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #280555;
    position: absolute;
    z-index: 9;
    right: 0;
    .MuiIconButton-sizeMedium {
      background-color: rgb(0 0 0 / 38%);
      width: 22px;
      height: 22px;
      color: #ffffff;
      line-height: 17px;
      svg {
        width: 18px;
      }
    }
  }

  h4 {
    font-size: 22px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0;
  }
  p {
    font-size: 12px;
    color: #000000;
  }
  .css-iwbsd8-MuiPaper-root-MuiDialog-paper {
    max-width: 800px;
  }
  &__banner {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    @media only screen and (max-width: 600px) {
      margin-top: 0px;
    }
  }
  .dialogBoxAthletepopup {
    margin: inherit;
  }
}

.checkout-details {
  p {
    color: #000000 !important;
    font-size: 18px;
    font-weight: 500;
  }
  &__info {
    background-color: rgba(88, 50, 215, 1);
    color: #ffffff;
    border-radius: 8px;
    padding: 50px;
    letter-spacing: 1px;
    small {
      font-size: 10px;
      font-weight: 500;
    }
    strong {
      font-size: 32px;
      font-weight: 600;
    }
    span {
      font-size: 14px;
      font-weight: 500;
    }
    em {
      font-style: normal;
      background-color: rgba(104, 63, 237, 1);
      padding: 4px 12px;
      border-radius: 14px;
      display: block;
      font-weight: 300;
      font-size: 12px;
    }
    hr {
      border-color: rgba(250, 250, 247, 0.54);
      border-style: dashed;
    }
  }
}

.billing-contact {
  p {
    color: #000000 !important;
    font-size: 18px;
    font-weight: 500;
  }
  &__info {
    background-color: rgba(248, 247, 253, 1);
    border: 1px solid rgba(189, 169, 254, 1);
    padding: 24px;
    border-radius: 8px;
    font-size: 14px;
    span {
      display: inline-block;
      min-width: 70px;
      font-weight: 300;
    }
    strong {
      font-weight: 600;
    }
  }
  &__savedcards {
    background-color: rgba(248, 247, 253, 1);
    border: 1px solid rgba(189, 169, 254, 1);
    padding: 14px;
    border-radius: 8px;
    font-size: 14px;
    span {
      display: inline-block;
      min-width: 70px;
      font-weight: 300;
    }
    strong {
      font-weight: 600;
    }
  }
  &__radio {
    span {
      font-size: 14px;
    }
  }
}
.ath-personal-detail {
  p {
    color: #000000 !important;
    font-size: 18px;
    font-weight: 500;
  }
}

.payment-receipt {
  background: url(../src/assets/images/Payment-Receipt.png) top no-repeat
    #f8f7fd;
  padding: 110px 20px 20px;
  letter-spacing: 1px;
  max-width: 600px;
  margin: 0 auto;
  h2 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  em {
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
  }
  &__details {
    font-size: 12px;
    strong {
      display: block;
      font-weight: 500;
    }
    small {
      display: block;
      margin-top: 8px;
      font-weight: 400;
      img {
        vertical-align: top;
      }
    }
  }
  &__summary {
    strong {
      display: block;
      font-size: 14px;
      font-weight: 600;
    }
    span {
      display: block;
      font-size: 12px;
      font-weight: 400;
      margin-top: 4px;
    }
  }
  &__amount {
    border: 1px solid #dfe4ea;
    border-radius: 3px;
    padding: 16px 24px;
    font-size: 12px;
    strong {
      font-weight: 600;
    }
    span {
      font-weight: 400;
    }
    hr {
      border-style: dashed;
    }
  }
  &__footer {
    border-top: 1px solid rgba(192, 192, 192, 1);
    padding-top: 10px;
    margin-top: 40px;
    p {
      font-size: 10px;
      font-weight: 400;
      margin: 0;
      a {
        color: rgba(138, 103, 254, 1);
        font-weight: 500;
        text-decoration: none;
      }
      &:last-child {
        margin-top: 8px;
      }
    }
  }
}
.iframe-component {
  width: 100%;
  min-height: 505px;
}
.primaty_btn_custom {
  padding: 15px 68px !important;
  width: 100%;
}
.reportsMainContainer {
  margin-left: -200px !important;
  margin-right: -170px !important;
}
.reportsMainContainerPdf {
  width: 1000;
  margin-left: -200px !important;
  margin-right: -170px !important;
  margin-bottom: 10;
}
.reportsMainContainerMobilePdf {
  margin-bottom: 10;
}
